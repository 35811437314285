import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { HeaderImage } from '../components/HeaderImage.jsx';
import styles from '../components/styles/privacy-policy.module.css';

const Privacy = () => {
  return (
    <Layout>
      <SEO title='プライバシーポリシー' />
      <HeaderImage
        title='PRIVACY POLICY'
        subTitle='プライバシーポリシー'
        fileName='privacy-policy.jpg'
      />
      <div className={styles.privacyContent}>
        <section className={styles.section}>
          <section className={styles.sectionContent}>
            <p>
              当社は、事業を実施する上で、お客様の個人情報がプライバシーを構成する重要な情報であることを深く認識し本人の権利の保護、個人情報に関する法規制等を遵守します。
            </p>
            <p>
              また、特定個人情報を含むすべての個人情報の保護に関する当社の基本方針とその取扱い基準を明確にした「個人情報保護マネジメントシステム」を策定し、IT技術の動向や社会的要請の変化、経営環境の変動等を常に認識しながら個人情報の適切な保護に努めることにより、お客様を尊重し、当社に対する期待と信頼に応えていきます。
            </p>
          </section>
          <section className={styles.sectionContent}>
            <h2 className={styles.sectionTitle}>個人情報保護方針</h2>
            <ol className={styles.numberOrderedListWrap}>
              <li className={`${styles.numberOrderedList} ${styles.clause}`}>
                <div className={styles.listNumber}></div>
                <div className={styles.listItem}>
                  個人情報は、システム開発事業、コンサルティング事業、及びSES事業における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱いを行わないための措置を講じます。
                </div>
              </li>
              <li className={`${styles.numberOrderedList} ${styles.clause}`}>
                <div className={styles.listNumber}></div>
                <div className={styles.listItem}>
                  個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。
                </div>
              </li>
              <li className={`${styles.numberOrderedList} ${styles.clause}`}>
                <div className={styles.listNumber}></div>
                <div className={styles.listItem}>
                  個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく事業の実情に合致した経営資源を注入し情報セキュリティ体制を継続的に向上させます。また、万一の際には速やかに是正措置を講じます。
                </div>
              </li>
              <li className={`${styles.numberOrderedList} ${styles.clause}`}>
                <div className={styles.listNumber}></div>
                <div className={styles.listItem}>
                  取り扱う個人情報について、本人から開示、訂正、利用停止及び苦情相談等のお問い合わせがあった場合は迅速かつ誠実に、適切な対応をさせていただきます。
                </div>
              </li>
              <li className={`${styles.numberOrderedList} ${styles.clause}`}>
                <div className={styles.listNumber}></div>
                <div className={styles.listItem}>
                  個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直してその改善を継続的に推進します。本方針は、当社のホームページなどに掲載することにより、いつでもどなたにも入手可能な措置を取るものとします。
                </div>
              </li>
            </ol>
            <div className={styles.signature}>
              <p>制定日：2019年4月1日</p>
              <p>バークリー・ジャパン株式会社</p>
              <p>代表取締役　井上　将之</p>
            </div>
          </section>
          <section className={styles.sectionContent}>
            <h2 className={styles.sectionTitle}>個人情報の取り扱いについて</h2>
            <p>
              バークリー・ジャパン株式会社は、当社がお預かりする個人情報に最新の注意を払います。
            </p>
            <ol className={styles.numberOrderedListWrap}>
              <li className={styles.numberOrderedList}>
                <h3 className={styles.subTitle}>個人情報の利用目的</h3>
                <table border='1' className={styles.purposeTable}>
                  <thead>
                    <tr>
                      <th>
                        <p>種別</p>
                      </th>
                      <th>
                        <p>利用目的</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <p>お客様に関する情報</p>
                      </th>
                      <td>
                        <p>お客様との商談・打合せ、契約の履行</p>
                        <p>当社が受託した情報システムを提供するため</p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p>取引先各社に関する情報</p>
                      </th>
                      <td>
                        <p>業務上必要な諸連絡・商談・契約等</p>
                        <p>取引先情報管理、支払い、収入処理</p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p>採用応募者に関する情報</p>
                      </th>
                      <td>
                        <p>採用応募者への採用情報等の提供・連絡</p>
                        <p>当社での採用業務管理</p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p>受付等で取得する情報</p>
                      </th>
                      <td>
                        <p>当社社屋への入退室管理</p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p>当社従業員情報</p>
                      </th>
                      <td>
                        <p>
                          業務上の連絡、報酬の支払、人事考課、社会保険関係の手続、
                          福利厚生の提供、その他雇用管理
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p>当社への問合せ情報</p>
                      </th>
                      <td>
                        <p>事業活動における連絡やアフターフォロー</p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p>退職者情報</p>
                      </th>
                      <td>
                        <p>
                          退職者との連絡、お問い合わせへの対応に利用するため
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p>名刺交換した際の情報</p>
                      </th>
                      <td>
                        <p>事業活動及びアフターフォローのため</p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p>パートナーの技術者情報</p>
                      </th>
                      <td>
                        <p>スキル照会、契約・請求手続きのため</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li className={styles.numberOrderedList}>
                <h3 className={styles.subTitle}>
                  個人情報の第三者に対する提供
                </h3>
                <p>
                  当社は、個人情報の管理を厳重に行うこととし、以下に掲げる場合を除き、ご本人の同意がない限り、第三者に対しデータを開示・提供することはいたしません。
                  また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。
                </p>
                <ol className={styles.circleOrderedList}>
                  <li>
                    <span>&#9312;</span>
                    <p>
                      人の生命、身体又は財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難である場合
                    </p>
                  </li>
                  <li>
                    <span>&#9313;</span>
                    <p>
                      公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難である場合
                    </p>
                  </li>
                  <li>
                    <span>&#9314;</span>
                    <p>
                      国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                    </p>
                  </li>
                  <li>
                    <span>&#9315;</span>
                    <p>
                      業務を円滑に遂行するため、利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合
                    </p>
                  </li>
                  <li>
                    <span>&#9316;</span>
                    <p>
                      合併その他の事由による事業の承継に伴って個人情報が提供される場合
                    </p>
                  </li>
                  <li>
                    <span>&#9317;</span>
                    <p>その他法令で認められた場合</p>
                  </li>
                </ol>
              </li>
              <li className={styles.numberOrderedList}>
                <h3 className={styles.subTitle}>
                  ウェブアクセス解析ツールの利用について
                </h3>
                <p>
                  当社が運営するサイトではサイトの利用状況を把握するために、以下の主要なツールを含む外部ウェブアクセス解析ツールを利用してユーザー情報を収集しております。各外部ウェブアクセス解析ツールによるユーザー情報の取扱方針は、それぞれのプライバシーポリシーをご覧ください。
                </p>
                <div className={styles.analyticsPrivacy}>
                  <p>
                    <Link
                      to='https://policies.google.com/privacy?hl=ja'
                      target='_blank'
                      className={styles.analyticsPrivacyLink}
                    >
                      ■Google Analytics：プライバシーポリシー
                    </Link>
                  </p>
                </div>
              </li>
              <li className={styles.numberOrderedList}>
                <h3 className={styles.subTitle}>
                  開示対象個人情報の開示等の手続について
                </h3>
                <p>
                  当社に対し、保有している開示対象個人情報の通知、開示、訂正、追加又は削除、利用の停止、消去又は第三者への提供の停止を請求される場合は、下記の個人情報苦情及びご相談窓口に対し、書面送付によりご請求下さい。必要書類および手続きの方法について記した書類を送付させていただきます。
                  また、開示対象個人情報の利用目的はお問い合わせ後速やかに回答いたします。
                </p>
                <p>
                  なお、開示対象個人情報の開示等のお申込みを受け付ける場合、当社は、必要に応じ、当該請求者に対して、請求者が請求の対象である個人情報に係るお客様本人であることを確認するため、当該確認に必要な書類等の提示を求めることができるものとします。
                </p>
              </li>
            </ol>
            <div className={styles.contactInfomation}>
              <h4>＜個人情報苦情及びご相談窓口＞</h4>
              <address>
                <p>バークリー・ジャパン株式会社 人事総務部</p>
                <p>東京都新宿区新宿5-2-3 MRCビル7F</p>
                <div className={styles.tel}>
                  <a href='tel:03-5363-2090'>03-5363-2090</a>
                </div>
              </address>
            </div>
          </section>
        </section>
      </div>
    </Layout>
  );
};

export default Privacy;
